import { ShowsTable } from '../../Components/ShowsTable';

import './index.css';

export const Tickets = () => {
    return (
        <>
            <div className="tickets-page">
                <ShowsTable />
            </div>
        </>
    )
}