import { useEffect, useState } from 'react';
import sanityClient from '../../client';
import ImageUrlBuilder from '@sanity/image-url';

import './index.css';
import GRADIENT from '../../Assets/logos/gradient_logo.svg';

export const Hero = () => {
    const [data, setData] = useState([]);
    const [width] = useState(window.innerWidth);
    const [visible, setVisible] = useState(true);
    const [scrolling, setScrolling] = useState(false);

    const builder = ImageUrlBuilder(sanityClient);

    const urlFor = (source) => {
        return builder.image(source);
    }

    const fetchData = async () => {
        try {
            const query = `*[_type == 'home'][0]`;
            const result = await sanityClient.fetch(query);
            setData(result.hero);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setVisible(scrollTop < window.innerHeight);
            setScrolling(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let startY = 0;
    
        const handleTouchMove = (event) => {
            const currentY = event.touches[0].clientY;
            const deltaY = currentY - startY;
            setVisible(deltaY > 200 || window.scrollY === 0);
            startY = currentY;
        };
    
        window.addEventListener('touchstart', (event) => {
            startY = event.touches[0].clientY;
        });
    
        window.addEventListener('touchmove', handleTouchMove);
    
        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);
    

    return (
        <>
            <section className="hero">
                {data && data.backgroundImage && (
                    <img className='background-image' src={urlFor(data.backgroundImage.asset._ref).url()}/>
                )}
                {data && data.logo && (
                    <a href='/' className={`${visible ? 'show' : 'hide'}`}>
                        <img 
                            className={`logo ${scrolling && width > 768 ? 'scrolling' : ''}`} 
                            src={urlFor(data.logo.asset._ref).url()} 
                        />
                        <img 
                            className={`logo gradient ${scrolling ? 'scrolling' : ''}`} 
                            src={GRADIENT} 
                        />
                    </a>
                )}
            </section>
        </>
    )
}